import dayjs from "dayjs";

export const CONTEXT_TYPES = {
  PAGE: "page",
};

export const SCOPE_TYPES = {
  PAGE: "page",
  APP: "account_app",
  SUBSCRIBER: "subscriber",
};

export const getOngoingOrUpcomingSchedule = (schedules) => {
  const now = dayjs();

  return (
    sortSchedules(schedules).find((schedule) => {
      const startsAt = dayjs(schedule.starts_at);
      const endsAt = dayjs(schedule.ends_at);

      return (
        (startsAt.isBefore(now) && endsAt.isAfter(now)) ||
        startsAt.isSame(now) ||
        startsAt.isAfter(now)
      );
    }) || null
  );
};

export const sortSchedules = (schedules) => {
  return [...schedules].sort((a, b) => {
    if (dayjs(a.starts_at).isSame(dayjs(b.starts_at))) {
      return dayjs(a.ends_at).isAfter(dayjs(b.ends_at)) ? 1 : -1;
    } else {
      return dayjs(a.starts_at).isAfter(dayjs(b.starts_at)) ? 1 : -1;
    }
  });
};
