import Vue from "vue"
import { Module, MutationTree, ActionTree, GetterTree } from "vuex"

import { ScheduledItem } from "@/types"

import ApiClient from "@/lib/api-client"
import { toDate } from "@/lib/date-utils"

export const toItemId = (id: string | number, type: string): string =>
  `${type.toLowerCase()}|${id}`

export interface ScheduleState {
  scheduledItems: Record<string, ScheduledItem>
  scheduledItemsByItem: Record<string, Record<string, boolean>>
}

const state: ScheduleState = {
  scheduledItems: {},
  scheduledItemsByItem: {}
}

const mutations: MutationTree<ScheduleState> = {
  setScheduledItem(state, item: ScheduledItem) {
    Vue.set(state.scheduledItems, item.id, item)

    // TODO: remove
    const itemKey = toItemId(item.item_id, item.item_type)

    if (!state.scheduledItemsByItem[itemKey]) {
      Vue.set(state.scheduledItemsByItem, itemKey, {})
    }
    Vue.set(state.scheduledItemsByItem[itemKey], item.id, true)
  },

  deleteScheduledItem(state, itemId: string | number) {
    Vue.delete(state.scheduledItems, itemId)

    // TODO: remove
    const item = state.scheduledItems[itemId]

    if (!item) {
      return
    }

    const itemKey = toItemId(item.item_id, item.item_type)

    if (state.scheduledItemsByItem[itemKey]) {
      Vue.delete(state.scheduledItemsByItem[itemKey], item.id)
    }
  }
}

const getters: GetterTree<ScheduleState, any> = {
  getScheduledItem: state => (
    id: string | number
  ): ScheduledItem | undefined => {
    return state.scheduledItems[id]
  },

  getScheduledItemsFor: state => (payload: {
    itemId: string | number | undefined
    itemType: string | undefined
    scopeType?: string | undefined
    contextId?: string | number | undefined
    contextType?: string | undefined
  }): ScheduledItem[] => {
    const { itemId, itemType, scopeType, contextId, contextType } = payload

    return Object.values(state.scheduledItems).filter(item => {
      if (
        itemId &&
        itemType &&
        (item.item_id !== itemId || item.item_type !== itemType)
      ) {
        return false
      }

      if (scopeType && item.scope_type !== scopeType) {
        return false
      }

      if (
        contextId &&
        contextType &&
        (item.context_id !== contextId || item.context_type !== contextType)
      ) {
        return false
      }

      return true
    })
  }
}

/** Interfaces for action payloads */
interface FetchScheduledItemsPayload {
  from?: string | Date | null
  to?: string | Date | null
  itemId?: string | number
  itemType?: string
  context?: string | number
  filter?: string
  page?: number
}

interface CreateScheduledItemPayload {
  itemId: string | number
  itemType?: string
  date: string | Date
}

interface RescheduleItemPayload {
  scheduledItemId: string | number
  date: string | Date
  shiftSubsequent?: boolean
}

interface DuplicateScheduledItemPayload {
  scheduledItemId: string | number
  date: string | Date
}

interface RemoveSubscriberSchedulesPayload {
  itemId: string | number
  itemType: string
}

/** Actions for the schedule module. */
const actions: ActionTree<ScheduleState, any> = {
  fetchScheduledItems(
    { commit },
    {
      from,
      to,
      itemId,
      itemType,
      context,
      filter,
      page
    }: FetchScheduledItemsPayload
  ) {
    return ApiClient.get("/schedule", {
      params: {
        item_id: itemId,
        item_type: itemType,
        context,
        filter,
        page,
        from: from ? toDate(from, "YYYY-MM-DD") : null,
        to: to ? toDate(to, "YYYY-MM-DD") : null
      }
    }).then(({ data }: { data: { items: ScheduledItem[] } }) => {
      if (data.items) {
        data.items.forEach((item: ScheduledItem) => {
          commit("setScheduledItem", item)
        })
      }
      return data
    })
  },

  fetchScheduleFilters() {
    return ApiClient.get("/schedule/filters").then(
      ({ data }: { data: any }) => {
        return data
      }
    )
  },

  createScheduledItem(
    { commit },
    { itemId, itemType = "Page", date }: CreateScheduledItemPayload
  ) {
    return ApiClient.post("/schedule", {
      item_id: itemId,
      item_type: itemType,
      date: toDate(date, "YYYY-MM-DD")
    }).then((res: { data: ScheduledItem }) => {
      commit("setScheduledItem", res.data)
      return res.data
    })
  },

  rescheduleItem(
    { commit },
    { scheduledItemId, date, shiftSubsequent = false }: RescheduleItemPayload
  ) {
    return ApiClient.put(`/schedule/${scheduledItemId}`, {
      date: toDate(date, "YYYY-MM-DD"),
      shift_subsequent: shiftSubsequent
    }).then(({ data }: { data: any }) => {
      commit("setScheduledItem", data.rescheduled_item)

      data.rescheduled_siblings.forEach((item: ScheduledItem) => {
        commit("setScheduledItem", item)
      })
      return data
    })
  },

  duplicateScheduledItem(
    { commit },
    { scheduledItemId, date }: DuplicateScheduledItemPayload
  ) {
    return ApiClient.post(`/schedule/${scheduledItemId}/duplicate`, {
      date: toDate(date, "YYYY-MM-DD")
    }).then((res: { data: ScheduledItem }) => {
      commit("setScheduledItem", res.data)
      return res.data
    })
  },

  removeScheduledItem({ commit }, scheduledItemId: string | number) {
    return ApiClient.delete(`/schedule/${scheduledItemId}`).then(() => {
      commit("deleteScheduledItem", scheduledItemId)
    })
  },

  removeSubscriberSchedulesByItem(
    { commit, getters },
    { itemId, itemType }: RemoveSubscriberSchedulesPayload
  ) {
    const scheduledItems: ScheduledItem[] = getters.getScheduledItemsFor({
      itemId,
      itemType
    })

    scheduledItems.forEach((item: ScheduledItem) => {
      commit("deleteScheduledItem", item.id)
    })
  }
}

const scheduleModule: Module<ScheduleState, any> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default scheduleModule
