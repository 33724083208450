import { ScheduledItem, ISODateString } from "@shared/types"
import {
  getOngoingOrUpcomingSchedule,
  sortSchedules,
  SCOPE_TYPES,
  CONTEXT_TYPES
} from "@shared/lib/schedule-utils"

import { toDate } from "@/lib/date-utils"
import i18n, { setLocale } from "@/store/i18n"

export const VIEW_TYPES = {
  WEEK: "week",
  MONTH: "month"
} as const

export const ANIMATION_DIRECTIONS = {
  FORWARD: "forward",
  BACKWARD: "backward"
} as const

export const FOR_YOU_FILTER = "_member"

export const INDEXED_DATE_FORMAT = "YYYY-MM-DD"

export const formatDate = (date: string | Date): string =>
  toDate(date, INDEXED_DATE_FORMAT)

export const CONTEXTS = {
  GLOBAL: {
    id: "global",
    title: ""
  },
  SUBSCRIBER: {
    id: "subscriber",
    title: i18n.t("lib.scheduleUtil.forYou")
  }
} as const

export const getLastSchedule = (
  schedules: ScheduledItem[]
): ScheduledItem | undefined => {
  const sortedSchedules = sortSchedules(schedules)
  return sortedSchedules.length > 0
    ? sortedSchedules[sortedSchedules.length - 1]
    : undefined
}

// TODO: Spec
export const shownEndsAt = (scheduledItem: ScheduledItem): ISODateString => {
  if (scheduledItem.is_multiday && scheduledItem.scope_type === "account_app") {
    // For scheduled items that are multiday and globally scheduled, we only want to show the item on the start day
    return scheduledItem.starts_at
  }

  return scheduledItem.ends_at
}

export const dayOfWeekInSchedule = (language: string): string[] => {
  setLocale(language)
  return [
    String(i18n.t("lib.scheduleUtil.sunday")),
    String(i18n.t("lib.scheduleUtil.monday")),
    String(i18n.t("lib.scheduleUtil.tuesday")),
    String(i18n.t("lib.scheduleUtil.wednesday")),
    String(i18n.t("lib.scheduleUtil.thursday")),
    String(i18n.t("lib.scheduleUtil.friday")),
    String(i18n.t("lib.scheduleUtil.saturday"))
  ]
}

export {
  sortSchedules,
  getOngoingOrUpcomingSchedule,
  SCOPE_TYPES,
  CONTEXT_TYPES
}
