import Vue from "vue"

export default {
  namespaced: true,

  state: {
    hiddenElements: [],
    isAlertDialogOpen: false,
    isViewSwitcherShown: false,
    ctaHeight: {} // ctaId => height
  },

  mutations: {
    setIsAlertDialogOpen(state, value) {
      state.isAlertDialogOpen = value
    },

    setHiddenElements(state, value = []) {
      state.hiddenElements = value
    },

    setCtaHeight(state, { id, height }) {
      Vue.set(state.ctaHeight, id, height)
    },

    setShowViewSwitcher(state, value) {
      state.isViewSwitcherShown = value
    },

    clearCtaHeight(state, id) {
      Vue.delete(state.ctaHeight, id)
    }
  },

  getters: {
    isAlertDialogOpen: state => state.isAlertDialogOpen,
    isElementHidden: state => element => state.hiddenElements.includes(element),
    getCtaHeight: state => id => state.ctaHeight[id]
  }
}
