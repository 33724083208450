import Vue from "vue"

import EventBus, { EVENTS } from "@/lib/event-bus"

const buildEventForAction = (pageId, uid) =>
  `${EVENTS.ACTION_PANEL_ACTION}-${pageId}-${uid}`

export const ACTIONS = {
  LEAVE_PROGRAM: "leave_program",
  TOGGLE_CALENDAR_VIEW: "toggle_calendar_view",
  VIEW_THREAD_PARTICIPANTS: "view_thread_participants",
  ADD_THREAD_PARTICIPANTS: "add_thread_participants",
  RENAME_THREAD: "rename_thread",
  LEAVE_THREAD: "leave_thread"
}

export default {
  namespaced: true,

  state: {
    actions: {}
  },

  mutations: {
    addAction(state, { pageId, uid, text, icon, callback }) {
      Vue.set(state.actions, pageId, state.actions[pageId] || [])

      if (!state.actions[pageId].find(action => action.uid === uid)) {
        Vue.set(state.actions, pageId, [
          ...state.actions[pageId],
          { uid, text, icon }
        ])
      }

      // Ensure latest callback is used
      // TODO: Update to scope to page id
      EventBus.$off(buildEventForAction(pageId, uid))
      EventBus.$on(buildEventForAction(pageId, uid), callback)
    },

    removeAction(state, { pageId, uid }) {
      Vue.set(
        state.actions,
        pageId,
        state.actions[pageId]?.filter(action => action.uid !== uid) || []
      )
      EventBus.$off(buildEventForAction(pageId, uid))
    }
  },

  actions: {
    // TODO: Take pageId here
    triggerAction(_, { pageId, uid }) {
      EventBus.$emit(buildEventForAction(pageId, uid))
    }
  }
}
