import Axios from "axios"
import joinUrl from "url-join"

import {
  cachePage,
  removeCachedPage,
  getCachedPageByPath
} from "@/lib/page-cache"
import isBuilderContext from "@/lib/is-platform/is-builder-context"

const MAX_CACHE_SIZE = 250
const memoryCache = new Set()

export const clearMemoryCache = () => memoryCache.clear()

export default path => {
  if (isBuilderContext()) return Promise.resolve()
  if (path.startsWith("/me/")) return Promise.resolve()

  if (memoryCache.has(path)) return getCachedPageByPath(path)

  const url = joinUrl(
    process.env.VUE_APP_APP_DATA_PATH,
    path === "/" ? "" : path,
    "index.json",
    `?ts=${new Date().getTime()}`
  )

  return Axios.get(url)
    .then(res => {
      cachePage(path, res.data)

      if (memoryCache.size >= MAX_CACHE_SIZE) {
        // Remove oldest entry (first item in set)
        memoryCache.delete(memoryCache.values().next().value)
      }

      memoryCache.add(path)

      return res.data
    })
    .catch(err => {
      if (err.response && err.response.status === 404) {
        removeCachedPage(path)
      }

      throw err
    })
}
